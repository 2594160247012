import React, { useState, useEffect } from 'react';
import { database, auth } from './firebase';
import { ref, onValue, push, remove, update } from 'firebase/database';
import { onAuthStateChanged } from 'firebase/auth';
import AuthPhone from './components/AuthPhone';
import './index.css';

function App() {
  const [faqs, setFaqs] = useState([]);
  const [editando, setEditando] = useState(null);
  const [editandoCampo, setEditandoCampo] = useState(null);
  const [textoEditando, setTextoEditando] = useState('');
  const [novaPergunta, setNovaPergunta] = useState('');
  const [novaResposta, setNovaResposta] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      
      // Só carrega os FAQs se o utilizador estiver autenticado
      if (user) {
        const faqRef = ref(database, 'faq');
        onValue(faqRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const faqArray = Object.entries(data).map(([id, value]) => ({
              id,
              ...value
            }));
            setFaqs(faqArray);
          } else {
            setFaqs([]);
          }
        });
      }
    });

    return () => unsubscribe();
  }, []);

  const adicionarFaq = () => {
    if (novaPergunta.trim() && novaResposta.trim()) {
      const faqRef = ref(database, 'faq');
      push(faqRef, {
        pergunta: novaPergunta,
        resposta: novaResposta
      });
      setNovaPergunta('');
      setNovaResposta('');
    }
  };

  const iniciarEdicaoCampo = (faq, campo) => {
    setEditando(faq.id);
    setEditandoCampo(campo);
    setTextoEditando(campo === 'pergunta' ? faq.pergunta : faq.resposta);
  };

  const salvarEdicao = () => {
    if (editando && textoEditando.trim()) {
      const faqRef = ref(database, `faq/${editando}`);
      const atualizacao = {
        [editandoCampo]: textoEditando
      };
      update(faqRef, atualizacao);
      setEditando(null);
      setEditandoCampo(null);
      setTextoEditando('');
    }
  };

  const cancelarEdicao = () => {
    setEditando(null);
    setEditandoCampo(null);
    setTextoEditando('');
  };

  const eliminarFaq = (id) => {
    const faqRef = ref(database, `faq/${id}`);
    remove(faqRef);
  };

  if (!isAuthenticated) {
    return <AuthPhone onAuthenticated={() => setIsAuthenticated(true)} />;
  }

  return (
    <div className="app">
      <header>
        <h1>Sistema de FAQ</h1>
      </header>

      <main>
        <div className="novo-faq">
          <h2>Novo Par</h2>
          <input
            type="text"
            placeholder="Pergunta"
            value={novaPergunta}
            onChange={(e) => setNovaPergunta(e.target.value)}
          />
          <textarea
            placeholder="Resposta"
            value={novaResposta}
            onChange={(e) => setNovaResposta(e.target.value)}
          />
          <button className="primary" onClick={adicionarFaq}>Adicionar</button>
        </div>

        <div className="lista-faqs">
          <h2>FAQs</h2>
          {faqs.length === 0 ? (
            <p className="sem-faqs">Nenhum FAQ adicionado ainda.</p>
          ) : (
            faqs.map(faq => (
              <div key={faq.id} className="faq-item">
                {editando === faq.id && editandoCampo === 'pergunta' ? (
                  <div className="campo-edicao">
                    <input
                      type="text"
                      value={textoEditando}
                      onChange={(e) => setTextoEditando(e.target.value)}
                      autoFocus
                    />
                    <div className="botoes-edicao">
                      <button className="success" onClick={salvarEdicao}>✓</button>
                      <button className="danger" onClick={cancelarEdicao}>✕</button>
                    </div>
                  </div>
                ) : (
                  <h3 onClick={() => iniciarEdicaoCampo(faq, 'pergunta')}>{faq.pergunta}</h3>
                )}
                
                {editando === faq.id && editandoCampo === 'resposta' ? (
                  <div className="campo-edicao">
                    <textarea
                      value={textoEditando}
                      onChange={(e) => setTextoEditando(e.target.value)}
                      autoFocus
                    />
                    <div className="botoes-edicao">
                      <button className="success" onClick={salvarEdicao}>✓</button>
                      <button className="danger" onClick={cancelarEdicao}>✕</button>
                    </div>
                  </div>
                ) : (
                  <p onClick={() => iniciarEdicaoCampo(faq, 'resposta')} style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>{faq.resposta}</p>
                )}
                
                <div className="acoes">
                  <button className="danger" onClick={() => eliminarFaq(faq.id)}>Eliminar</button>
                </div>
              </div>
            ))
          )}
        </div>
      </main>
    </div>
  );
}

export default App;
