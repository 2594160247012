import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: "AIzaSyCfpVGEC3yYLycamlx3l16z537kjDQiQKw",
    authDomain: "myrtus-407915.firebaseapp.com",
    databaseURL: "https://myrtus-407915-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "myrtus-407915",
    storageBucket: "myrtus-407915.firebasestorage.app",
    messagingSenderId: "334814369975",
    appId: "1:334814369975:web:1c0b72d3e02be03b0c9532",
    measurementId: "G-QLKPNVGTEY"
};

const app = initializeApp(firebaseConfig);

// Inicializar o App Check com reCAPTCHA v3
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LeI6XQqAAAAAHrL5XCqzwM3U3l182QPKRq-51pX'),
    isTokenAutoRefreshEnabled: true
});

export const auth = getAuth(app);
export const database = getDatabase(app);

// Configurar o idioma para português
auth.languageCode = 'pt';
