import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

function AuthPhone({ onAuthenticated }) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função de callback do reCAPTCHA
  window.recaptchaLoadedCallback = () => {
    console.log('reCAPTCHA carregado com sucesso');
  };

  useEffect(() => {
    // Limpar qualquer instância existente
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }

    // Inicializar reCAPTCHA quando o componente montar
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA resolvido - habilitar envio de SMS
        console.log('reCAPTCHA verificado com sucesso');
      },
      'expired-callback': () => {
        // Callback quando o reCAPTCHA expira
        console.log('reCAPTCHA expirou');
        setError('O reCAPTCHA expirou. Por favor, tente novamente.');
      }
    });

    // Cleanup ao desmontar o componente
    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  const formatPhoneNumber = (number) => {
    // Garantir que o número começa com +351 (Portugal)
    if (!number.startsWith('+')) {
      return '+351' + number;
    }
    return number;
  };

  const handleSendCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier);
      setVerificationId(confirmationResult);
      //alert('Código SMS enviado com sucesso!');
    } catch (err) {
      setError('Erro ao enviar o código: ' + err.message);
      console.error(err);
      // Recriar o reCAPTCHA em caso de erro
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'invisible',
          'callback': (response) => {
            console.log('reCAPTCHA verificado com sucesso');
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const result = await verificationId.confirm(verificationCode);
      // Utilizador autenticado com sucesso
      console.log('Utilizador autenticado:', result.user);
      onAuthenticated(); // Chamar a função para atualizar o estado de autenticação
    } catch (err) {
      setError('Erro na verificação do código: ' + err.message);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-phone">
      <h2>Autenticação por Telefone</h2>
      
      {!verificationId ? (
        <form onSubmit={handleSendCode}>
          <div>
            <label htmlFor="phone">Número de Telefone:</label>
            <input
              type="tel"
              id="phone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="+351 912345678"
              required
            />
          </div>
          <div id="recaptcha-container"></div>
          <button type="submit" disabled={loading}>
            {loading ? 'A enviar...' : 'Enviar Código'}
          </button>
        </form>
      ) : (
        <form onSubmit={handleVerifyCode}>
          <div>
            <label htmlFor="code">Código de Verificação:</label>
            <input
              type="text"
              id="code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="123456"
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'A verificar...' : 'Verificar Código'}
          </button>
        </form>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default AuthPhone;
