import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Registra e atualiza o Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      // Desregistra qualquer SW existente
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        await registration.unregister();
      }

      // Registra o novo SW
      const registration = await navigator.serviceWorker.register('/sw.js');
      
      // Força atualização se houver uma nova versão
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }

      // Atualiza a página quando o novo SW assumir o controle
      registration.addEventListener('controllerchange', () => {
        window.location.reload();
      });

      console.log('Service Worker registrado com sucesso:', registration.scope);
    } catch (error) {
      console.error('Erro ao registrar Service Worker:', error);
    }
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
